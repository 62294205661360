
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

import Cart from './cart.js'

const application = Application.start()
const context = require.context("shopify_script", true, /_controller\.js$/)
application.load(definitionsFromContext(context))


const cart = new Cart();