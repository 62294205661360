import { Controller } from "stimulus";
import Cookies from 'js-cookie';

import { APP_URL } from './variables.js.erb';


export default class extends Controller {
  static targets = [ "dropdown" ]


  initialize() {

    // If no cart items then don't show widget
    //if (parseInt(this.data.get('cart-count')) < 1 ) return;

    this.params = new URLSearchParams(window.location.search)
    
    this.shop = this.data.get('shop')
    this.isOffsetAdded = this.data.get('is-offset-added');
    this.variantId = this.data.get('variant-id')
    this.type = this.data.get('type')
    this.openOnReload = this.data.get('open-on-reload')
    this.enabled = this.data.get('enabled')
    this.delay = this.data.get('delay')

    this.meta = this.data.get('meta') ? JSON.parse(this.data.get('meta')) : null;


    this.placement = this.data.get('placement')
    this.placementPosition = this.data.get('placement-position')

    
    this.settings = JSON.parse(this.data.get('settings'))

    
    setTimeout(() => this.init() , parseInt(this.delay));
    
      

  }

  connect() {

    // If no cart items then don't show widget
    //if (parseInt(this.data.get('cart-count')) < 1 ) return;


    
     
  }

  init = () => {
    const preview = this.params.get('carbonclick')

    // If a placement was provided but not found then don't show widget
    if (this.placement) {
      if (!this.moveWidget()) return;
    }

    

    if (this.enabled == 'true') {
      this.show();

      this.recordImpression();
    
    } else if (preview && preview == 'true') {
      this.show();

      this.recordImpression();
    
    } else {

    }
    
  }


  moveWidget = () => {

    // return true as its already in correct place
    if (!this.placement) return true;

    this.placementElement = document.querySelector(this.placement)

    if (!this.placementElement) return false;

    this.placementElement.insertAdjacentElement(this.placementPosition, this.element);

    return true;

  }



  


  recordImpression = () => {

    if (this.enabled != 'true') return;

    // Don't send impression if don't need to yet
    if (this.meta && this.meta.next_impression) {

      const nextImpression = this.meta.next_impression;
      const now = Date.now();

      if (nextImpression > now) return;

    }


    const data = {
      shop: this.shop,
      widget_type: this.type
    }

    fetch(`${APP_URL}/api/v1/impressions.json`, {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
          'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then((result) => {
      
     
    })
    .catch(error => console.log(error));
  }

  


  show = () => {
    this.element.classList.add('is-enabled');
  }





  toggleCart = () => {

    const updateValue = this.isOffsetAdded == 'false' ? 1 : 0

    const data = {
        updates: {[this.variantId]: updateValue }
    }

    fetch(`/cart/update.js`, {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    })    
    .then((cart) => {
       this.onToggleCartSuccess(cart)
    });
  }


  onToggleCartSuccess = (cart) => {

    if (this.type == 'compressed' || this.type == 'mini' || this.openOnReload == 'true') {
      Cookies.set('cc-opencart', false, { expires: 1 })
    }

    window.location.reload();
       
  }


  more = (event) => {
    event.preventDefault();

    this.dropdownTarget.classList.toggle('is--active')
  }



  
}