import Cookies from 'js-cookie';

export default class Cart {

    constructor() {
        this.checkCartOpen();
    }

    checkCartOpen = () => {
       
        const cartCookie = 'cc-opencart'
        const cookie = Cookies.get(cartCookie);
    
        if (cookie) {
          this.openCart();
          Cookies.remove(cartCookie);
        } 
    }



    openCart() {    
       

        // Notify external listers
        document.dispatchEvent(new Event("cc-opencart"));

        // Brooklyn, Boundless
        this.cartLink = document.querySelector('a.cart-link');
    
        // Narrative
        if (!this.cartLink) this.cartLink = document.querySelector('.ajax-cart__toggle')
    
        // Prestige
        if (!this.cartLink) this.cartLink = document.querySelector('a[data-action="open-drawer"]')
    
        // Colors Theme
        if (!this.cartLink) this.cartLink = document.querySelector('[data-action="toggle-cart"]')
    
        // drinkarepa.com
        if (!this.cartLink) this.cartLink = document.querySelector('.mini_cart')
    
        // patricks.co
        if (!this.cartLink) this.cartLink = document.querySelector('[aria-controls="CartDrawer"]')
    
        // Venue (e.g. www.greystonewines.co.nz/)
        if (!this.cartLink) this.cartLink = document.querySelector('.js-cart-trigger')
        
        // Ella (e.g. wanderlostandfound.com)
        if (!this.cartLink) this.cartLink = document.querySelector('[data-cart-toggle]')
    
        // Supply (e.g. iotstore.nz)
        if (!this.cartLink) this.cartLink = document.querySelector('.header-cart-btn')
    
        // Elda (e.g. www.redumbrelladesign.com)
        if (!this.cartLink) this.cartLink = document.querySelector('.toggle-cart-summary')
    
        // Modular (e.g. www.biddyandmay.co.nz)
        if (!this.cartLink) this.cartLink = document.querySelector('.js-cartToggle')
    
        // Pipeline (e.g. greenresidence.store)
        if (!this.cartLink) this.cartLink = document.querySelector('a[class="CartToggle"]')
    
        // Lucid (e.g. spacifystore.myshopify.com)
        if (!this.cartLink) this.cartLink = document.querySelector('span[id="site-cart-handle"] > a[class="block-fade"]')
    
        // Blockshop (e.g. www.twogreenstones.com)
        if (!this.cartLink) this.cartLink = document.querySelector('a.header--cart-link')
    
        // Pop (e.g. wilddispensary.co.nz)
        if (!this.cartLink) this.cartLink = document.querySelector('a.cart-toggle')
    
        // ShowTime (e.g. www.budoonline.co.uk)
        if (!this.cartLink) this.cartLink = document.querySelector('a.cart-popup')                        
    
        // Lebe (e.g e-volutionretail.com)
        if (!this.cartLink) this.cartLink = document.querySelector('a.icon-cart')                        
    
        // Cascade (e.g. cook-nelson.myshopify.com)
        if (!this.cartLink) this.cartLink = document.querySelector('a[id="CartLink"]')
    
        // 'The Perfect One'  (e.g. frenshmo.com.au)
        if (!this.cartLink) this.cartLink = document.querySelector('a.slide-menu-cart')            
    
        // 'Belle'  (e.g. identitydiamonds.com)
        if (!this.cartLink) this.cartLink = document.querySelector('a.site-header__cart')            
    
        // 'Testament' (e.g. outpostnz.com)
        if (!this.cartLink) this.cartLink = document.querySelector('a[class="my-cart-link"]')            
    
        // Atlantic (e.g. www.clay-pot.com)
        if (!this.cartLink) this.cartLink = document.querySelector('div[class="mini-cart-wrap"]')
    
        // Atlantic (e.g. www.clay-pot.com)
        if (!this.cartLink) this.cartLink = document.querySelector('a[class="cartToggle"]')
    
        // Peddle (e.g. peddlenz.com)
        if (!this.cartLink) this.cartLink = document.querySelector('a.header__cart-toggle')
    
        // Wokiee (e.g, heartlandempire.com, worldmask.co)
        if (!this.cartLink) this.cartLink = document.querySelector('button[data-tooltip="Cart"]')
    
        // Debutify (e.g. topofthegalaxy.com)
        if (!this.cartLink) this.cartLink = document.querySelector('a.js-drawer-open-button-right')
    
        // e.g. goodnesscandles.com
        if (!this.cartLink) this.cartLink = document.querySelector('a[data-header-drawer-toggle="cart"]')
        
        // e.g. heekee.myshopify.com
        if (!this.cartLink) this.cartLink = document.querySelector('a.sidebar__cart-handle')
    
        // e.g. woken-coffee.myshopify.com
        if (!this.cartLink) this.cartLink = document.querySelector('.minicart-wrapper .cart-icon')
    
        // e.g. mershacoffee.com
        if (!this.cartLink) this.cartLink = document.querySelector('.header__cart .cart__link')
    
        // e.g. liamsworldshop.com
        if (!this.cartLink) this.cartLink = document.querySelector('.cart-link__bubble')
    
        // Payna (e.g. ollieandjames.com)
        if (!this.cartLink) this.cartLink = document.querySelector('div.cart-wrap > a.cart-active')
    
        // Galleria (e.g. adventureplease.com)
        if (!this.cartLink) this.cartLink = document.querySelector('.nav-standard-top-area-actions li a.lin.lin-basket')
    
        // Maker (e.g. pure-pod-life-style-clothing.myshopify.com)
        if (!this.cartLink) this.cartLink = document.querySelector('.cart-link a.modal--link')
    
        // e.g. bamboo.land
        if (!this.cartLink) this.cartLink = document.querySelector('.jas_nt_side a')            
    
        // Gecko (e.g. godspeedsocks.com)
        if (!this.cartLink) this.cartLink = document.querySelector('.jas-sidebar-cart i.pe-7s-shopbag')
    
        // e.g. breakawaymatcha.com
        if (!this.cartLink) this.cartLink = document.querySelector('.header-tools a.CartToggle')
    
        // e.g. doublespringbox.myshopify.com
        if (!this.cartLink) this.cartLink = document.querySelector('button#offcanvas-cart-icon')            
    
        // Foodly (e.g. paleo-scavenger.myshopify.com)
        if (!this.cartLink) this.cartLink = document.querySelector('button#cart-open-btn')                                    
    
        // e.g. www.everestisles.com
        if (!this.cartLink) this.cartLink = document.querySelector('a.site-header__cart')                                    
    
        // e.g. animalsnthings.myshopify.com
        if (!this.cartLink) this.cartLink = document.querySelector('a.header__btn-cart')                                    

        // e.g. hairnation.com.au
        if (!this.cartLink) this.cartLink = document.querySelector('div.wrapper-top-cart > a.cart-icon')

        // e.g. 99thmonkey.com.au
        if (!this.cartLink) this.cartLink = document.querySelector('div.hup_cart > a.header-cart')

        // e.g. margauxny.com
        if (!this.cartLink) this.cartLink = document.querySelector('button.navigation__button > div.cart-with-badge')

        // e.g. www.golden-hat.shop
        if (!this.cartLink) this.cartLink = document.querySelector('.header-cart-txt')

        if (this.cartLink) this.cartLink.click();
    }
}