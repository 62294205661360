import { Controller } from "stimulus";
import Cookies from 'js-cookie';

import { APP_URL } from './variables.js.erb';

export default class extends Controller {
  static targets = [ "dropdown" ]


  initialize() {


    this.params = new URLSearchParams(window.location.search)
    
    this.shop = this.data.get('shop')
    this.isOffsetAdded = this.data.get('is-offset-added');
    this.variantId = this.data.get('variant-id')
    this.type = this.data.get('type')
    this.openOnReload = this.data.get('open-on-reload')
    this.enabled = this.data.get('enabled')

    


  }

  connect() {

    this.init();

    this.recordImpression();

     
  }



  init = () => {
    const preview = this.params.get('carbonclick')

    if (this.enabled == 'true') {
      this.show();
    
    } else if (preview && preview == 'true') {
      this.show();
    
    } else {

    }

    
  }


  recordImpression = () => {

    if (this.enabled != 'true') return;

    const data = {
      shop: this.shop,
      widget_type: this.type
    }

    fetch(`${APP_URL}/api/v1/impressions.json`, {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
          'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then((result) => {
      console.log(result)
    })
    .catch(error => console.log(error));
  }



  moveStyleToHead = () => {

    if (typeof Vue === 'undefined') return;
    
    const styleElement = document.getElementById('cc-style');
    
    if (!styleElement) return;
    
    
    const headElement = document.head || document.getElementsByTagName('head')[0];
    
    const style = document.createElement('style');

	  headElement.appendChild(style);

  	style.type = 'text/css';
    
    if (style.styleSheet) {
      // This is required for IE8 and below.
      style.styleSheet.cssText = styleElement.innerHTML;
    } else {
      style.appendChild(document.createTextNode(styleElement.innerHTML));
    }

  }

  


  show = () => {
    this.element.classList.add('is-enabled');
  }


  toggleCart = () => {

    const updateValue = this.isOffsetAdded == 'false' ? 1 : 0

    const data = {
        updates: {[this.variantId]: updateValue }
    }

    fetch(`/cart/update.js`, {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    })    
    .then((cart) => {
       this.onToggleCartSuccess(cart)
    });
  }


  onToggleCartSuccess = (cart) => {

    if (this.type == 'compressed' || this.openOnReload == 'true') {
      Cookies.set('cc-opencart', false, { expires: 1 })
    }

    window.location.reload();
       
  }


  more = (event) => {
    event.preventDefault();

    this.dropdownTarget.classList.toggle('is--active')
  }

  
}